import React, { useEffect, useState } from "react";

// import { useUpdateAdminContext } from "../../../../Providers/SuperAdmin/UpdateAdminProvider";
import DriverprofileHeader from "./DriverprofileHeader";
import SingleDriverSideBar from "./SingleDriverSideBar";
import Modal from "../../../Modal";
import Rides from "./Rides";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { SentimentDissatisfiedRounded } from "@mui/icons-material";
import { Button } from "@mui/material";
import { IoNotificationsOutline } from "react-icons/io5";
import { TbRectangleFilled } from "react-icons/tb"
import { ButtonGroup,Box, CircularProgress } from "@material-ui/core";
import { toast,ToastContainer } from "react-toastify";
import { jwtDecode } from "jwt-decode";

const SingleAdmin = () => {
  const[loading,setLoading]=useState(false)
  const{id}=useParams()
  const[noOfOnGoingTrips,setNoOfOnGoingTrips]=useState(0)
  const[noOfCompletedTrips,setNoOfCompletedTrips]=useState(0)
  const[noOfCancelledTrips,setNofOfCancelledTrips]=useState(0)
  const[driver,setDriver]=useState({})
  const[noOfTotalTrips,setNoOfTotalTrips]=useState(0)
  const[last24HoursRidden,setLast24HoursRidden]=useState(0)
  const[last7DaysRidden,setLast7DaysRidden]=useState(0)
  const[last30DaysRidden,setLast30DaysRidden]=useState(0)
  const[allTimeRidden,setAllTimeRidden]=useState(0)
  const[signatureUrl,setSignatureUrl]=useState("")
  const[profilePhotoUrl,setProfilePhotoUrl]=useState("")
  const[licenseUrl,setLicenseUrl]=useState("")
  const[IDCardUrl,setIDCardUrl]=useState("")
  const[isApproved,setIsApproved]=useState(false)
  const now=new Date()
  let navigate=useNavigate()
  const thirtyDaysAgo = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000); // 30 days in milliseconds
  const sevenDaysAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
  const twentyFourHoursAgo = new Date(now.getTime() - 24 * 60 * 60 * 1000);
 const[docs,setDocs]=useState([])
  let getDriverById=async()=>{
    const token=localStorage.getItem("token")
    let response=await axios.get(`https://api.triphog.net/api/v1/driver/getdriver/${id}`,{headers:{'Authorization':token}})
    let _response=await axios.get("https://api.triphog.net/api/v1/trip/gettrips",{headers:{'Authorization':token}})
   
    let ONGOING=_response.data.trips.filter((trip)=>{
      return(trip.status=="On Route" && trip.driverRef==id)
    })
    let COMPLETED=_response.data.trips.filter((trip)=>{
      return(trip.status=="Completed" && trip.driverRef==id)
    })
    let CANCELLED=_response.data.trips.filter((trip)=>{
      return(trip.status=="Cancelled" && trip.driverRef==id)
    })
    let TOTAL=_response.data.trips.filter((trip)=>{
      return(trip.driverRef==id)
    })

    console.log(ONGOING.length)
    console.log(CANCELLED)
    console.log(COMPLETED.length)
    ONGOING=ONGOING.length
    CANCELLED=CANCELLED.length
    COMPLETED=COMPLETED.length
    TOTAL=TOTAL.length
    console.log("ON Going",ONGOING)
    let MyTrips=_response.data.trips.filter((trip)=>{
      return(trip.driverRef==id)
    })
    console.log("My Trips Of Driver With Id",id,"Are",MyTrips)
    let mycompletedRides=MyTrips.filter((trip)=>{
      return(trip.status=="Completed")
    })
    let last24HoursCompletedRides=mycompletedRides.filter((trip)=>{
      return(new Date(trip.createdAt)>=twentyFourHoursAgo)
    })
    let last7DaysCompletedRides=mycompletedRides.filter((trip)=>{
      return(new Date(trip.createdAt)>=sevenDaysAgo)
    })
    let last30DaysCompletedRides=mycompletedRides.filter((trip)=>{
      return(new Date(trip.createdAt)>=thirtyDaysAgo)
    })
    if(mycompletedRides.length>0)
    {
      let hoursRidden=0
      for(let trip of mycompletedRides)
      {
        hoursRidden=hoursRidden+trip.timeTaken
      }
      setAllTimeRidden(hoursRidden)
    }
    if(last24HoursCompletedRides.length>0)
    {
      let hoursRidden=0
      for(let trip of last24HoursCompletedRides)
      {
        hoursRidden=hoursRidden+trip.timeTaken
      }
      setLast24HoursRidden(hoursRidden)

    }
    if(last7DaysCompletedRides.length>0)
    {
      let hoursRidden=0
      for(let trip of last7DaysCompletedRides)
      {
        hoursRidden=hoursRidden+trip.timeTaken
      }
      setLast7DaysRidden(hoursRidden)
    }
    if(last30DaysCompletedRides.length>0)
    {
      let hoursRidden=0
      for(let trip of last30DaysCompletedRides)
      {
        hoursRidden=hoursRidden+trip.timeTaken
      }
      setLast30DaysRidden(hoursRidden)

    }

    setNoOfOnGoingTrips(ONGOING)
    setNofOfCancelledTrips(CANCELLED)
    setNoOfCompletedTrips(COMPLETED)
    setNoOfTotalTrips(TOTAL)
    setProfilePhotoUrl(response.data.driver.profilePhotoUrl)
    setSignatureUrl(response.data.driver.signatureUrl)
    setIDCardUrl(response.data.driver.IDCardUrl)
    setIsApproved(response.data.driver.isApproved)
    setLicenseUrl(response.data.driver.licenseUrl)
    setDriver(response.data.driver)
    setDocs(response.data.driver.docs) 
  }
  const handleGoBack = () => {
    navigate(-1); // Navigate to the previous page
  };
  
  useEffect(()=>{
    getDriverById()
  },[])
  const decodedUser=jwtDecode(localStorage.getItem("token"))

  let checkPermission=(permission)=>{
    console.log("Decoded User Permissions",decodedUser)
    if(decodedUser.role=="Admin")
    {
      return false
    }
    else{
      return (decodedUser.accessibilities.includes(permission))
    }
    

  }
  if(decodedUser.role=="User" &&(!checkPermission("Access To View Driver")))
    {
      return (
        <div className="flex items-center justify-center h-screen bg-deepBlue mt-[20px] rounded-md">
          <div className="bg-white p-8 rounded-lg shadow-lg text-center w-full max-w-md">
            <h1 className="text-3xl font-bold text-deepBlue mb-4">Unauthorized</h1>
            <p className="text-gray-700 mb-6">
              You do not have access to this page. Please contact the administrator or go back.
            </p>
            <button
              onClick={handleGoBack}
              className="bg-deepBlue text-white px-4 py-2 rounded-lg hover:bg-deepBlue-dark transition-colors"
            >
              Go Back
            </button>
          </div>
        </div>
      );
  
    }
    else{
  if(Object.keys(driver).length==0)
  {
    return(
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
        <CircularProgress size={60} thickness={5} color="inherit" />
      </div>)
}
  else
  {
  return (
    <>
      <div className="bg-white rounded-[20px] p-[30px] mt-[20px] laptop:mr-[30px]">
      {loading &&(<div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
        <CircularProgress size={60} thickness={5} color="inherit" />
      </div>)}
      <div className="flex items-center  text-m_c laptop:text-lap_d font-[600] justify-between ">
        {/* <Link to="/admin">
          <div className="flex items-center gap-2">
            <IoArrowBackOutline />
            <h2 className="laptop:text-lap_c text-m-c">{`Driver ${firstName} ${lastName} Details`}</h2>
          </div>
        </Link> */}
        <Button style={{display:"flex",flexDirection:"row"}} onClick={()=>{
         navigate(-1) 
        }}>
          <img style={{height:"26px",width:"23px"}} src={require('./left-arrow.png')}/>
          <h5 style={{marginRight:"8px"}}>BACK</h5>
          
          </Button>
          <div className="flex flex-col justify-center items-center">
            <img src={profilePhotoUrl} style={{height:"150px",width:"150px"}} className="rounded-full" />
          </div>
        <div className="grid grid-cols-2 text-lap_b items-center">
         {isApproved?<div>
            <button
              className="bg-vibrantPink text-white font-[400] py-[7px] px-[15px] rounded-lg"
              onClick={() => {
               async function denyDriver(){
                  try{
                    setLoading(true)
                    let response=await axios.put(`https://api.triphog.net/api/v1/admin/denydriver/${id}`)
                    if(response.data.success)
                    {
                      setLoading(false)
                      toast.success("Successfully Approved")
                      getDriverById()

                    }
                    else{
                      setLoading(false)
                      toast.error("There Was Error While Denying Driver")   
                    }
                  }
                  catch(e)
                  {
                    setLoading(false)
                    toast.error("There Was Error While Denying Driver"+e.message)
  
                  }
                }
                denyDriver()
        
              }}
            >
              Deny Driver
            </button>
          </div>: <div>
            <button className="bg-deepBlue text-white font-[400] py-[7px] px-[15px] rounded-lg"  onClick={() => {
               async function approveDriver(){
                  try{
                    setLoading(true)
                    let response=await axios.put(`https://api.triphog.net/api/v1/admin/approvedriver/${id}`)
                    if(response.data.success)
                    {
                      setLoading(false)
                      toast.success("Successfully Approved")
                      getDriverById()

                    }
                    else{
                      setLoading(false)
                      toast.error("There Was Error While Denying Driver")   
                    }
                  }
                  catch(e)
                  {
                    setLoading(false)
                    toast.error("There Was Error While Denying Driver")
  
                  }
                }
                approveDriver()
        
              }}>
              Approve Driver
            </button>
          </div>} 

         
        </div>
      </div>
        <div>
          <form
            className="text-darkgray laptop:text-lap_b"
            // onSubmit={handleSubmit}
          >
            <div className="laptop:flex gap-16 laptop:my-[16px]">
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label className="">First Name</label>
                <br />
                <input
                value={driver.firstName}
                  type="text"
                  disabled
                  // placeholder={firstName}
                  // onChange={(event) => {
                  //   setFirstName(event.target.value);
                  // }}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px]">
                <label>Last Name</label>
                <br />
                <input
                disabled
                value={driver.lastName}
                  type="text"
                  // placeholder={lastName}
                  // onChange={(event) => {
                  //   setLastName(event.target.value);
                  // }}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
            </div>
            <div className="laptop:flex gap-16 laptop:my-[16px]">
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label className="">Your Email</label>
                <br />
                <input
                disabled
                  type="text"
                  value={driver.EMailAddress}
                  // placeholder={email}
                  // onChange={(event) => {
                  //   setEmail(event.target.value);
                  // }}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label>Phone Number</label>
                <br />
                <input
                  type="text"
                  disabled
                  value={driver.phoneNumber}
                  // placeholder={phoneNumber}
                  // onChange={(event) => {
                  //   setPhoneNumber(event.target.value);
                  // }}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
            </div>
            <div className="laptop:flex gap-16 laptop:my-[16px]">
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label className="">Hourly Pay</label>
                <br />
                <input
                  type="text"
                  disabled
                  value={driver.hourlyPay}
                  // placeholder={phoneNumber}
                  // onChange={(event) => {
                  //   setPhoneNumber(event.target.value);
                  // }}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label className="">Gender</label>
                <br />
                <select disabled
                  // onChange={(event) => {
                  //   setGender(event.target.value);
                  // }}
                   value={driver.gender}
                  className="mt-[8px] border-[0.6px] cursor-pointer rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                >
                  <option>Select Gender</option>
                  <option value={"male"}>Male</option>
                  <option value={"female"}>Female</option>
                </select>
              </div>
            </div>
            <div className="laptop:flex gap-16 laptop:my-[16px]">
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label className="">Location</label>
                <br />
                <input
                disabled
                  type="text"
                  value={driver.location}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label>Vehicle Name</label>
                <br />
                <input
                disabled
                  type="text"
                  value={driver.vehicleName}
                  className="mt-[8px] border-[0.6px] rounded-md laptop:text-lap_b text-[30px] py-[10px] w-full px-[20px] bg-[#F4F7FF] focus:outline-none focus:border-gray-400 focus:bg-white"
                />
              </div>
            </div>
            <div className="laptop:flex gap-16 laptop:my-[16px]">
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label>Signature</label>
                <br />
               
                {signatureUrl && <img src={signatureUrl} alt="Signature" className="mt-2 max-w-full h-20 object-contain" />}
              </div>
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label>ID Card</label>
                <br />
               
                {IDCardUrl && <img src={IDCardUrl} alt="ID Card" className="mt-2 max-w-full h-20 object-contain" />}
              </div>
            </div>
            <div className="laptop:flex gap-16 laptop:my-[16px]">
              <div className="laptop:w-[45%] py-[18px] laptop:py-[5px] laptop:text-lap_b text-[30px] w-[100%]">
                <label>License</label>
                <br />
                
                {licenseUrl && <img src={licenseUrl} alt="License" className="mt-2 max-w-full h-20 object-contain" />}
              </div>
            </div>
            {/* Add Document Button Section */}
<div className="flex justify-center my-4 laptop:my-[16px]">
  <div className="w-full max-w-[90%] laptop:w-[45%] py-[18px] text-center">
    <button
      type="button"
      onClick={() => document.getElementById('file-input').click()}
      className="bg-deepBlue text-white rounded-md py-[10px] px-[20px] transition"
    >
      Documents
    </button>
    <input
      id="file-input"
      type="file"
      accept="application/pdf"

      className="hidden"
    />
    
  </div>
</div>

{/* Document Preview Section */}
<div className="flex justify-center">
  <div className="w-full max-w-[90%]">
    {docs.length > 0 && (
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-4 sm:gap-6">
        {docs.map((doc, index) => (
          <div
            key={index}
            className="flex flex-col justify-between w-full min-w-[200px] max-w-full h-[350px] border rounded-lg shadow-md p-4 bg-white transition-transform transform hover:scale-105 hover:shadow-lg overflow-hidden"
          >
            <h3 className="text-lg font-bold truncate">{doc.title}</h3>
            <div className="flex justify-center mt-3">
             
              <button
                type="button"
                className="bg-deepBlue text-white rounded-full px-4 py-2 transition ml-2 hover:bg-blue-700"
                onClick={() => window.open(doc.url, "_blank")} // Open doc in a new window
              >
                View
              </button>
            </div>
          </div>
        ))}
      </div>
    )}
  </div>
</div>
 
            
          </form>
        </div>
        {/* <Rides /> */}
      </div>

      <div className="bg-white laptop:mt-[-80px]">
      <div className="laptop:grid hidden laptop:sticky laptop:top-0 laptop:left-0 laptop:right-0 p-[25px] bg-white">
      <div className="w-[100%]">
        <div className="flex float-right text-lap_e cursor-pointer">
          <IoNotificationsOutline />
        </div>
        <h2 className="text-lap_c mt-[60px] font-[600]">Total Trips</h2>
        <h2 className="text-lap_d font-[700] text-deepBlue">{noOfTotalTrips}</h2>
        <div className="grid grid-cols-2 items-center my-[20px] gap-[10%]">
          <div>
            <div className="flex items-center my-[10px]">
              <h3 className="text-[#409261] text-lap_c">
                <TbRectangleFilled />
              </h3>
              &nbsp;&nbsp;&nbsp;&nbsp;{" "}
              <span className="text-deepBlue text-lap_b">{noOfOnGoingTrips}</span> &nbsp;&nbsp;{" "}
              <span className="text-darkgray text-lap_b">Ongoing</span>
            </div>
            <div className="flex items-center my-[10px]">
              <h3 className="text-[#D98634] text-lap_c">
                <TbRectangleFilled />
              </h3>
              &nbsp;&nbsp;&nbsp;&nbsp;{" "}
              <span className="text-deepBlue text-lap_b">{noOfCompletedTrips}</span> &nbsp;&nbsp;{" "}
              <span className="text-darkgray text-lap_b">Completed</span>
            </div>
            <div className="flex items-center my-[10px]">
              <h3 className="text-[#E42346] text-lap_c">
                <TbRectangleFilled />
              </h3>
              &nbsp;&nbsp;&nbsp;&nbsp;{" "}
              <span className="text-deepBlue text-lap_b">{noOfCancelledTrips}</span> &nbsp;&nbsp;{" "}
              <span className="text-darkgray text-lap_b">Cancelled</span>
            </div>
          </div>
          <div>Chart</div>
        </div>
      </div>
      <div>
        <h1 className="text-lap_c font-[600] my-[20px]">Hours Ridden</h1>
        <div className="grid grid-cols-2 my-[25px]">
          <div>
            <h3 className="text-lap_b text-[#273240]">Last 24 hours</h3>
            <h2 className="text-lap_e text-deepBlue font-[700]">{last24HoursRidden.toFixed(2)}</h2>
          </div>
          <div>
            <h3 className="text-lap_b text-[#273240]">Last 7 days</h3>
            <h2 className="text-lap_e text-deepBlue font-[700]">{last7DaysRidden.toFixed(2)}</h2>
          </div>
        </div>
        <div className="grid grid-cols-2 my-[25px]">
          <div>
            <h3 className="text-lap_b text-[#273240]">Last 30 days</h3>
            <h2 className="text-lap_e text-deepBlue font-[700]">{last30DaysRidden.toFixed(2)}</h2>
          </div>
          <div>
            <h3 className="text-lap_b text-[#273240]">All Time</h3>
            <h2 className="text-lap_e text-deepBlue font-[700]">{allTimeRidden.toFixed(2)}</h2>
          </div>
        </div>
      </div>
      <div>
        
        <div>
          {/* <MapContainer
            center={position}
            zoom={13}
            scrollWheelZoom={true}
            style={{ height: "300px" }}
            className="rounded-lg border"
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={position}>
              <Popup>Driver</Popup>
            </Marker>
          </MapContainer> */}
        </div>
      </div>
    </div>
      </div>
      <>
        {/* {status && (
          <Modal status={status} setStatus={setStatus} message={modalMessage} />
        )} */}
      </>
    </>
  );
}
    }
};

export default SingleAdmin;
