import React, { useEffect, useState } from 'react';
import {
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TableHead,
  CircularProgress,
  TableRow,
  TextField,
  Button,
  Typography,
  TablePagination,
  Box,
} from '@mui/material';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; 
import 'react-date-range/dist/theme/default.css'; 
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast,ToastContainer } from 'react-toastify';
import * as XLSX from 'xlsx';  // Import xlsx
const initialRows = [
  // Your initial rows
];
const Payrol = () => {
  const [rows, setRows] = useState(initialRows);
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const[AMOUNTPAID,setAmountPaid]=useState(0)
  const[AMOUNTTOBEPAID,setAmountToBePaid]=useState(0)
  const[paymentType,setPaymentType]=useState("hourly")
  const [driversWhoDrove, setDriversWhoDrove] = useState([]);
  const navigate = useNavigate();
 const handlePaymentTypeChange=(e)=>{
    setPaymentType(e.target.value)
  }

  // Fetch drivers logic
  let getDriversWhoDrove = async () => {
    const token=localStorage.getItem("token")
    setLoading(true)
    try{
    let response = await axios.get(`https://api.triphog.net/api/v1/driver/getdriven/${dateRange[0]}/${dateRange[1]}`,{headers:{"Authorization":token}});
    let tripsResponse=await axios.get("https://api.triphog.net/api/v1/trip/gettrips",{headers:{"Authorization":token}})
    let driversResponse=await axios.get("https://api.triphog.net/api/v1/driver/getdrivers",{headers:{"Authorization":token}})

    console.log(tripsResponse.data.trips)
    console.log(driversResponse.data.drivers)
  
    console.log(response.data);
    setDriversWhoDrove(response.data.driversWhoDrove);
    setLoading(false)
    }
    catch(e){
      toast.error("Something Went Wrong!")
      setLoading(false)
    }
  };
  const[loading,setLoading]=useState(false)

  useEffect(() => {
    getDriversWhoDrove();
  }, []);
  // Handle date change
  const handleDateChange = async (ranges) => {
    const token=localStorage.getItem("token")
    setLoading(true)
    try{
    setDateRange([ranges.selection.startDate, ranges.selection.endDate]);
    let response = await axios.get(`https://api.triphog.net/api/v1/driver/getdriven/${ranges.selection.startDate}/${ranges.selection.endDate}`,{headers:{"Authorization":token}});
    let tripsResponse=await axios.get("https://api.triphog.net/api/v1/trip/gettrips",{headers:{"Authorization":token}})
    console.log(tripsResponse.data.trips)
    let driversResponse=await axios.get("https://api.triphog.net/api/v1/driver/getdrivers",{headers:{"Authorization":token}})

    console.log(tripsResponse.data.trips)
    console.log(driversResponse.data.drivers)
    console.log(response.data);
    setDriversWhoDrove(response.data.driversWhoDrove);
    setLoading(false)
    }
    catch(e){
      toast.error("Something Went Wrong!")
      setLoading(false)
    }
  };

  // Export to Excel functionality
  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(driversWhoDrove.map(driver => ({
      "Driver Name": `${driver.firstName} ${driver.lastName}`,
      "Hours Ridden": driver.hoursRidden,
      "Hourly Pay": driver.hourlyPay,
      "Amount Paid": driver.amountPaid,
      "Amount to be Paid": Math.max(0, driver.hoursRidden * driver.hourlyPay - driver.amountPaid)
    })));
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Payroll Data");
    XLSX.writeFile(wb, "payroll_data.xlsx");
  };

  return (
    <Container sx={{ mt: 4, width: "1500px" }}>
       {loading && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <CircularProgress size={60} thickness={5} color="inherit" />
        </div>
      )}
      <Paper sx={{ p: 3, borderRadius: 2, width: "1280px", backgroundColor: '#ffffff', boxShadow: 3 }}>
        <Box style={{width:"100%",display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
        
        <Typography variant="h4" gutterBottom>
          Payrol
        </Typography>  
        <FormControl sx={{ mb: 2, minWidth: 200 }}>
        <InputLabel 
  sx={{ 
    fontFamily: "Cabin", 
    color: "#30325E !important",
    '&.Mui-focused': { color: "#30325E !important" },
    '&:hover': { color: "#30325E !important" }
  }}
>
  Payment Type
</InputLabel>


  <Select
    value={paymentType}
    label="Payment Type"
    sx={{
      fontFamily: "Cabin",
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: "#30325E"
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: "#30325E"
      }
    }}
    onChange={handlePaymentTypeChange}
  >
    <MenuItem value="hourly" sx={{ fontFamily: "Cabin" }}>Hourly Pay</MenuItem>
    <MenuItem value="mileage" sx={{ fontFamily: "Cabin" }}>Pay by Mileage</MenuItem>
    <MenuItem value="direct" sx={{ fontFamily: "Cabin" }}>Direct Pay</MenuItem>
  </Select>
</FormControl>
  
        </Box>
       
        <DateRangePicker
          color="#30325E"
          rangeColors={["#30325E"]}
          ranges={[{ startDate: dateRange[0], endDate: dateRange[1], key: 'selection' }]}
          onChange={handleDateChange}
        />
        <TableContainer component={Paper} sx={{ mt: 2, borderRadius: 2, backgroundColor: '#ffffff' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{fontFamily:"Cabin"}}>Driver Name</TableCell>
                <TableCell style={{fontFamily:"Cabin"}} >Hours Ridden</TableCell>
                <TableCell style={{fontFamily:"Cabin"}}>Hourly Pay</TableCell>
                <TableCell style={{fontFamily:"Cabin"}}>Amount Paid</TableCell>
                <TableCell style={{fontFamily:"Cabin"}}>Amount to be Paid</TableCell>
              </TableRow>
            </TableHead>
            <TableBody >
              {driversWhoDrove.map((row, index) => (
                <TableRow onClick={()=>{
                }}  key={index} style={{ cursor: 'pointer' }}>
                  <TableCell onClick={()=>{
                  navigate('/admin/paymentdetails', { state: { driver: row,dateRange:dateRange } })

                  }} style={{fontFamily:"Cabin"}}>{row.firstName} {row.lastName}</TableCell>
                  <TableCell onClick={()=>{
                  navigate('/admin/paymentdetails', { state: { driver: row,dateRange:dateRange } })

                  }} style={{fontFamily:"Cabin"}}>{row.hoursRidden}</TableCell>
                  <TableCell>
                    <TextField
                      type="number"
                      value={row.hourlyPay}
                      onChange={async (e) => {
                        setLoading(true)
                        const token = localStorage.getItem("token");
                        try{
                        let response = await axios.put(`https://api.triphog.net/api/v1/driver/update/${row._id}`, { hourlyPay: e.target.value }, { headers: { 'Authorization': token } });
                        if (response.data.success) {
                          let updatedDrivers = await axios.get(`https://api.triphog.net/api/v1/driver/getdriven/${dateRange[0]}/${dateRange[1]}`);
                          setDriversWhoDrove(updatedDrivers.data.driversWhoDrove);
                          setLoading(false)
                          toast.success("Hourly Rate Updated!")
                        }
                      }
                      catch(e){
                        setLoading(false)
                        toast.error("Something Went Wrong While Updating Houly Rate!")
                      }

                      }}
                      fullWidth
                      variant="outlined"
                      size="small"
                      style={{fontFamily:"Cabin"}}
                      inputProps={{style:{fontFamily:"Cabin"}}}
                    />
                  </TableCell>
                  <TableCell onClick={()=>{
                  navigate('/admin/paymentdetails', { state: { driver: row,dateRange:dateRange } })

                  }} style={{fontFamily:"Cabin"}}>{row.amountPaid}</TableCell>
                  <TableCell onClick={()=>{
                  navigate('/admin/paymentdetails', { state: { driver: row,dateRange:dateRange } })

                  }} style={{fontFamily:"Cabin"}} >{Math.max(0, row.hoursRidden * row.hourlyPay - row.amountPaid)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          
        </TableContainer>
        <button  style={{ marginTop: "10px",backgroundColor:"#30325E",height:"40px",width:"150px",color:"white",borderRadius:"5px" }} onClick={exportToExcel}>
          Download as Excel
        </button>
      </Paper>
    </Container>
  );
};

export default Payrol;
