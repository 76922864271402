import React, { useEffect, useState } from 'react';
import {
  Avatar, Rating, CircularProgress
} from '@mui/material';
import axios from 'axios';

const TripReviews = () => {
  const [tripReviews, setTripReviews] = useState([]);
  const [adminReviews, setAdminReviews] = useState([]); // Independent Reviews State
  const [loading, setLoading] = useState(true); // Loading state
  const [searchTerm, setSearchTerm] = useState(""); // Search state
  const [filteredReviews, setFilteredReviews] = useState([]); // Filtered trip reviews state
  const [filteredAdminReviews, setFilteredAdminReviews] = useState([]); // Filtered admin reviews state

  // Fetch trip reviews and admin reviews
  const getTripReviews = async () => {
    setLoading(true); // Start loading
    const token = localStorage.getItem("token");
    let tripResponse = await axios.get("https://api.triphog.net/api/v1/trip/gettrips", { headers: { 'Authorization': token } });
    let adminReviewsResponse = await axios.get("https://api.triphog.net/api/v1/admin/getallreviews");

    // Handle trip reviews
    let tripReviewsList = [];
    for (let trip of tripResponse.data.trips) {
      if (trip.reviews.length > 0) {
        for (let _review of trip.reviews) {
          tripReviewsList.push({
            addedON: _review.addedON,
            text: _review.description,
            addedByPhotoUrl: trip.patientPhotoUrl,
            addedBy: trip.patientName,
            rating: _review.rating,
            images: _review.images
          });
        }
      }
    }

    // Handle admin independent reviews
    const independentReviewsList = adminReviewsResponse.data.allReviews.map(review => ({
      addedON: review.addedON,
      text: review.description,
      addedBy: review.from,
      rating: review.rating,
    }));

    setTripReviews(tripReviewsList);
    setAdminReviews(independentReviewsList); // Set independent reviews
    setFilteredReviews(tripReviewsList); // Set both filtered and original trip reviews
    setFilteredAdminReviews(independentReviewsList); // Set both filtered and original independent reviews
    setLoading(false); // Stop loading
  };

  useEffect(() => {
    getTripReviews();
  }, []);

  // Filter reviews based on search input
  const handleSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearchTerm(searchValue);
    const filteredTrip = tripReviews.filter((review) =>
      review.addedBy.toLowerCase().includes(searchValue) || 
      review.text.toLowerCase().includes(searchValue)
    );
    const filteredAdmin = adminReviews.filter((review) =>
      review.addedBy.toLowerCase().includes(searchValue) ||
      review.text.toLowerCase().includes(searchValue)
    );
    setFilteredReviews(filteredTrip);
    setFilteredAdminReviews(filteredAdmin);
  };

  const renderReviewCard = (_review) => (
    <div
      className='bg-deepBlue'
      style={{
        display: "flex",
        flexDirection: "column",
        height: "250px",
        width: "85%",
        borderRadius: "10px",
        margin: "auto",
        marginBottom: "20px",  // Space between reviews
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",  // Adds shadow
        transition: "transform 0.3s ease, box-shadow 0.3s ease"  // Smooth transition for hover effects
      }}
      onMouseOver={(e) => {
        e.currentTarget.style.transform = "scale(1.02)";  // Slightly enlarge the card on hover
        e.currentTarget.style.boxShadow = "0 8px 16px rgba(0, 0, 0, 0.2)";  // Enhance shadow on hover
      }}
      onMouseOut={(e) => {
        e.currentTarget.style.transform = "scale(1)";  // Reset scale on mouse out
        e.currentTarget.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.1)";  // Reset shadow on mouse out
      }}
    >
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <div style={{ display: "flex", flexDirection: "row", marginLeft: "30px", marginTop: "20px" }}>
          <Avatar src={_review.addedByPhotoUrl}>{_review.addedBy[0]}</Avatar>
          <div style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}>
            <h2 style={{ color: "white" }}>{_review.addedBy}</h2>
            <h5 style={{ color: "white" }}>{new Date(_review.addedON).toLocaleDateString()}</h5>
          </div>
        </div>
        <Rating style={{ marginRight: "20px", marginTop: "20px" }} value={_review.rating} readOnly />
      </div>
      <div style={{ color: "white", margin: "20px" }}>
        {_review.text}
      </div>
    </div>
  );

  return (
    <div style={{ width: "100%", display: "flex", flexDirection: "column", marginTop: "5px", backgroundColor: "white", borderRadius: "15px", height: "80vh", overflowY: "scroll" }}>
      <div className='review_top' style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "50px", width: "90%" }}>
        <h1 className='trip_review_heading' style={{ marginLeft: "20px" }}>Reviews And Ratings</h1>
        <input
          placeholder='Search'
          value={searchTerm}
          onChange={handleSearch}
          style={{ width: "200px", height: "30px", border: "2px solid black", borderRadius: "5px", marginRight: "20px" }}
          className='search_review'
        />
      </div>

      {loading ? ( // Show spinner while data is being fetched
        <div className='text-deepBlue' style={{ display: "flex" ,justifyContent: "center", alignItems: "center", height: "100%" }}>
          <CircularProgress size={80} thickness={4} style={{ color: '#1E3A8A' }} />
        </div>
      ) : (
        <>
          <div style={{ height: "80%", overflowY: "scroll", padding: "20px" }}>
            {
              filteredReviews.length === 0 ? (
                <h1>No Reviews!</h1>
              ) : filteredReviews.map(renderReviewCard)
            }
          </div>

          {/* Independent Reviews Section */}
          <h2 style={{ marginLeft: "20px", marginTop: "40px", fontWeight: "bold" }}>Independent Reviews</h2>
          <div style={{ height: "80%", overflowY: "scroll", padding: "20px" }}>
            {
              filteredAdminReviews.length === 0 ? (
                <h1>No Independent Reviews!</h1>
              ) : filteredAdminReviews.map(renderReviewCard)
            }
          </div>
        </>
      )}
    </div>
  );
};

export default TripReviews;
