import React, { useEffect, useState } from "react";
import { AiOutlineMessage } from "react-icons/ai";
import { CiBrightnessUp, CiCreditCard2, CiLogin } from "react-icons/ci";
import { FaRegMoon } from "react-icons/fa";
import { IoMdMenu } from "react-icons/io";
import { IoSettingsOutline } from "react-icons/io5";
import { MdOutlineCalendarMonth, MdSpaceDashboard } from "react-icons/md";
import { RiUserFollowLine } from "react-icons/ri";
import { Lock } from '@mui/icons-material';
import { useLocation, useNavigate } from "react-router-dom";
import MobileNavBar from "./MobileNavBar";
import { Description, DriveEta, TripOriginTwoTone } from "@mui/icons-material";
import { MdGroup } from "react-icons/md";
import { Star } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import PaymentIcon from "@mui/icons-material/Payment";
import SecurityIcon from '@mui/icons-material/Security';
import { jwtDecode } from "jwt-decode";

const Header = () => {
  const [isSubAdmin, setIsSubAdmin] = useState(false);
  let location = useLocation();
  const [menu, setMenu] = useState(false);
  let navigate = useNavigate();
  useEffect(() => {
    let decodedUser = jwtDecode(localStorage.getItem("token"));
    if (decodedUser.role == "User") {
      setIsSubAdmin(true);
    }
  }, []);
  const toggleMenu = () => {
    setMenu(!menu);
  };

  const iconStyle = (path) => ({
    backgroundColor: location.pathname === path ? "#30325E" : "transparent",
    borderRadius: "20%",
    padding: "15px",
    color: location.pathname === path ? "white" : "lightslategray",
  });
  const decodedUser = jwtDecode(localStorage.getItem("token"));

  let checkPermission = (permission) => {
    console.log("Decoded User Permissions", decodedUser);
    if (decodedUser.role == "Admin") {
      return false;
    } else {
      return decodedUser.accessibilities.includes(permission);
    }
  };

  return (
    <div className="relative">
      {menu && <MobileNavBar menu={menu} setMenu={setMenu} />}

      {/* Mobile */}
      <div className="laptop:hidden mt-[20px] mx-[-20px] text-[70px] pb-[20px] mb-[30px] border-solid border-b-[3px] border-b-[#959595] box-border">
        <div className="px-[40px] flex justify-between items-center my-[-12px]">
          <div className="ml-[-20px] siteLogo">
            <Link to={"/superadmin"}>
              <img 
              src="https://img.freepik.com/premium-vector/restaurant-logo-design-concept-vector-food-logo-design-concept_9850-2590.jpg"
              height={"300px"} 
              width={"300px"} 
              alt="Trip Hug" />
            </Link>
          </div>
          <div className="float-right nav_toggle_handle" onClick={toggleMenu}>
            <IoMdMenu />
          </div>
        </div>
      </div>

      {/* Laptop */}
      <div className="laptop:max-w-[120px] hidden bg-[#FFFFFF] laptop:sticky laptop:top-0 laptop:left-0 laptop:right-0 laptop:h-screen laptop:place-items-center laptop:grid">
        <div className="cursor-pointer text-[white]">
          <svg
            width="46"
            height="38"
            viewBox="0 0 46 38"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            {/* SVG content */}
          </svg>
        </div>

        {/* Scrollable content */}
        <div
          className="laptop:grid laptop:pt-[120px] laptop:gap-y-[20px] laptop:place-items-center laptop:h-full laptop:overflow-y-auto sidebar_div"
          style={{
            // paddingRight: "50px",
            scrollbarColor: "lightslategray", // Black thumb, light track
            direction: "rtl", // Right-to-left to place scrollbar on the left side
            display:"flex",
            flexDirection: "column"
          }}
        >
          <style>
            {`
              /* Scrollbar styles for Webkit (Chrome, Safari, Edge) */
              ::-webkit-scrollbar {
                width: 8px;
                height: 8px;
                left:0,
                paddingRight:"50px"
              }
              ::-webkit-scrollbar-track {
                background: lightslategray;
              }
              ::-webkit-scrollbar-thumb {
                background-color: lightslategray; /* Black scrollbar thumb */
                border-radius: 10px; /* Rounded thumb */
                border: 3px solid lightslategray; /* Match track color */
              }
              /* Apply the same RTL (left-side scrollbar) styling for Webkit browsers */
              .laptop .overflow-y-auto::-webkit-scrollbar {
                width: 8px;
                left:0px;
                paddingLeft:30px
              }
            `}
          </style>

          <Tooltip title="Dashboard" placement="right">
            <div onClick={() => navigate("/admin")} style={iconStyle("/admin")}>
              <MdSpaceDashboard className="text-[24px]" />
            </div>
          </Tooltip>
          {!isSubAdmin && (
            <Tooltip title="Users" placement="right">
              <div
                onClick={() => navigate("/admin/users")}
                style={iconStyle("/admin/users")}
              >
                <RiUserFollowLine className="text-[24px]" />
              </div>
            </Tooltip>
          )}
          <Tooltip title="Trips" placement="right">
            <div
              onClick={() => navigate("/admin/trips")}
              style={iconStyle("/admin/trips")}
            >
              <TripOriginTwoTone className="text-[24px]" />
            </div>
          </Tooltip>
          <Tooltip title="Meetings" placement="right">
            <div
              onClick={() => navigate("/admin/meeting")}
              style={iconStyle("/admin/meeting")}
            >
              <MdOutlineCalendarMonth className="text-[24px]" />
            </div>
          </Tooltip>
          <Tooltip title="Drivers" placement="right">
            <div
              onClick={() => navigate("/admin/drivers")}
              style={iconStyle("/admin/drivers")}
            >
              <DriveEta className="text-[24px]" />
            </div>
          </Tooltip>
          <Tooltip title="Patients" placement="right">
            <div
              onClick={() => navigate("/admin/patient")}
              style={iconStyle("/admin/patient")}
            >
              <MdGroup className="text-[24px]" />
            </div>
          </Tooltip>
          {(decodedUser.role == "User" &&
            checkPermission("Access To Payrol")) ||
            (decodedUser.role == "Admin" && (
              <Tooltip title="Payroll" placement="right">
                <div
                  onClick={() => navigate("/admin/payrol")}
                  style={iconStyle("/admin/payrol")}
                >
                  <CiCreditCard2 className="text-[24px]" />
                </div>
              </Tooltip>
            ))}
          {(decodedUser.role == "User" &&
            checkPermission("Access To Download Logs")) ||
            (decodedUser.role == "Admin" && (
              <Tooltip title="Logs" placement="right">
                <div
                  onClick={() => navigate("/admin/triplogs")}
                  style={iconStyle("/admin/triplogs")}
                >
                  <Description className="text-[24px]" />
                </div>
              </Tooltip>
            ))}
            
          <Tooltip title="Chat" placement="right">
            <div
              onClick={() => navigate("/admin/chat")}
              style={iconStyle("/admin/chat")}
            >
              <AiOutlineMessage className="text-[24px]" />
            </div>
          </Tooltip>
          <Tooltip title="Settings" placement="right">
            <div
              onClick={() => navigate("/admin/settings")}
              style={iconStyle("/admin/settings")}
            >
              <IoSettingsOutline className="text-[24px]" />
            </div>
          </Tooltip>
          <Tooltip title="Reviews" placement="right">
            <div
              onClick={() => navigate("/admin/tripreviews")}
              style={iconStyle("/admin/tripreviews")}
            >
              <Star className="text-[24px]" />
            </div>
          </Tooltip>
          <Tooltip title="My Documents" placement="right">
            <div
              onClick={() => navigate("/admin/documents")}
              style={iconStyle("/admin/documents")}
            >
              <Lock/>
            </div>
          </Tooltip>
          {!isSubAdmin && (
            <Tooltip title="Payment" placement="right">
              <div
                onClick={() => navigate("/admin/plan")}
                style={iconStyle("/admin/plan")}
              >
                <PaymentIcon className="text-[24px]" />
              </div>
            </Tooltip>
          )}

          {/* Scrollable Logout */}
          <div className="laptop:grid laptop:pt-[120px] laptop:gap-y-[20px] laptop:place-items-center">
            <Tooltip title="Logout" placement="right">
              <div
                onClick={() => {
                  localStorage.removeItem("token");
                  navigate("/admin/login");
                }}
                className="text-[24px] text-[#1F1C2E] cursor-pointer"
              >
                <CiLogin />
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
