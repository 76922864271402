import axios from "axios";
import { jwtDecode } from "jwt-decode";
import React, { useEffect, useState } from "react";

const Wellcome = () => {
  const decodedUser=jwtDecode(localStorage.getItem("token"))
  const[admin,setAdmin]=useState({})
  let getAdminById=async()=>{
    let response=await axios.get(`https://api.triphog.net/api/v1/superadmin/admin/getById/${localStorage.getItem("token")}`)
 console.log("Admin Data",response.data)
 setAdmin(response.data.data)
    setAdmin(response.data.data)
  }
  useEffect(()=>{
    getAdminById()
  },[])
  return (
    <div className="laptop:grid laptop:grid-cols-[8%_auto] laptop:items-center hidden bg-[#F4F7FF] laptop:px-[30px] laptop:pt-[8px]">
      <div className="bg-[#C8F7DC] h-[60px] w-[60px] rounded-full">
        <img
          className="h-full w-full rounded-full"
         src={decodedUser.role=="User"?decodedUser.profilePhotoUrl:admin.photo}
          alt="profile"
        />
      </div>
      <div>
        <h1 className="text-[16px] text-[#262626]">
          <b>Welcome Back</b>, {decodedUser.role=="User"?decodedUser.fullName:admin.firstName+" "+admin.lastName}👋{" "}
        </h1>
        <h3 className="text-[10px] text-[#26262657] font-[400]">
          Monthly Ultimate User
        </h3>
      </div>
    </div>
  );
};

export default Wellcome;
