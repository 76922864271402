import React, { createContext, useContext, useState } from "react";

// Create the context
const TripFiltersContext = createContext();

// Custom provider component
export const TripFiltersProvider = ({ children }) => {
  // State variables
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [IsDateSelected, setIsDateSelected] = useState(false);
  const [SelectedDate, setSelectedDate] = useState(null);
  const [currentTripPage, setCurrentPage] = useState(1);
  const[selectedFilter,setFilter]=useState('today')

  // Functions to update values by accepting an argument
  const updateIsFilterApplied = (value) => {
    console.log("Filter Applying",value)
    setIsFilterApplied(value)};
  const updateIsDateSelected = (value) => {
    console.log("Date Selected VAlue",value)
    setIsDateSelected(value)};
  const updateSelectedDate = (value) => {
    console.log("Updated date value",value)
    setSelectedDate(value)};
  const updateCurrentPage = (value) => {
    console.log("Current Page Set To:",value)
    setCurrentPage(value)};
  const updateFilter=(value)=>{
    console.log("Filter Applying",value)
    setFilter(value)}

  // Function to get the full context
  const getTripFiltersContext = () => ({
    isFilterApplied,
    IsDateSelected,
    SelectedDate,
    currentTripPage,
  });

  // Context value
  const value = {
    isFilterApplied,
    updateIsFilterApplied,
    IsDateSelected,
    updateIsDateSelected,
    SelectedDate,
    updateSelectedDate,
    currentTripPage,
    updateCurrentPage,
    selectedFilter,
    updateFilter,
    getTripFiltersContext,
  };

  return (
    <TripFiltersContext.Provider value={value}>
      {children}
    </TripFiltersContext.Provider>
  );
};

// Custom hook for using the context
export const useTripFiltersContext = () => useContext(TripFiltersContext);
